import StandardModal from '../../shared/modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import { Heading, HeadingSize } from '../../shared/text/Heading';
import Button, { ButtonSize, ButtonType } from '../../shared/form-control/Button';
import { useManageDocumentClassesWizard } from '../../../contexts/ManageDocumentClassesContext';

const StartCreateClassStep = () => {
  const { nextStep } = useManageDocumentClassesWizard();
  const { t } = useTranslation('documents');
  return (
    <StandardModal title={t('manage-wizard.create-class.title')}>
      <div className="flex h-[60vh] flex-grow flex-col items-center justify-center overflow-auto px-1 text-center">
        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.5" width="64" height="64" rx="32" fill="#F2F2F2" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.8057 24.1207H25.2559C24.8925 24.1207 24.5977 23.8258 24.5977 23.4624V16.1309C24.5977 15.7675 24.8925 15.4727 25.2559 15.4727H38.8057C39.1691 15.4727 39.4639 15.7675 39.4639 16.1309V23.4612C39.4639 23.8258 39.1691 24.1207 38.8057 24.1207ZM25.9142 22.8041H38.1474V16.7892H25.9142V22.8041Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.403 35.1422H17.627C17.2636 35.1422 16.9688 34.8474 16.9688 34.4839V27.7091C16.9688 27.3456 17.2636 27.0508 17.627 27.0508H24.4019C24.7653 27.0508 25.0602 27.3456 25.0602 27.7091V34.4839C25.0613 34.8474 24.7665 35.1422 24.403 35.1422ZM18.2865 33.8256H23.7448V28.3674H18.2865V33.8256Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.4331 35.1422H39.6583C39.2949 35.1422 39 34.8474 39 34.4839V27.7091C39 27.3456 39.2949 27.0508 39.6583 27.0508H46.4331C46.7966 27.0508 47.0914 27.3456 47.0914 27.7091V34.4839C47.0903 34.8474 46.7966 35.1422 46.4331 35.1422ZM40.3154 33.8256H45.7737V28.3674H40.3154V33.8256Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0106 49.4586C12.7797 49.4586 10.9648 47.6438 10.9648 45.4129C10.9648 43.182 12.7797 41.3672 15.0106 41.3672C17.2414 41.3672 19.0563 43.182 19.0563 45.4129C19.0563 47.6438 17.2414 49.4586 15.0106 49.4586ZM15.0106 42.6826C13.5054 42.6826 12.2814 43.9066 12.2814 45.4118C12.2814 46.9169 13.5054 48.1409 15.0106 48.1409C16.5157 48.1409 17.7397 46.9169 17.7397 45.4118C17.7397 43.9078 16.5157 42.6826 15.0106 42.6826Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.702 49.4586C35.4711 49.4586 33.6562 47.6438 33.6562 45.4129C33.6562 43.182 35.4711 41.3672 37.702 41.3672C39.9328 41.3672 41.7477 43.182 41.7477 45.4129C41.7477 47.6438 39.9328 49.4586 37.702 49.4586ZM37.702 42.6826C36.1968 42.6826 34.9728 43.9066 34.9728 45.4118C34.9728 46.9169 36.1968 48.1409 37.702 48.1409C39.2071 48.1409 40.4311 46.9169 40.4311 45.4118C40.4311 43.9078 39.2071 42.6826 37.702 42.6826Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.3582 49.4586C24.1274 49.4586 22.3125 47.6438 22.3125 45.4129C22.3125 43.182 24.1274 41.3672 26.3582 41.3672C28.5891 41.3672 30.4039 43.182 30.4039 45.4129C30.4039 47.6438 28.5891 49.4586 26.3582 49.4586ZM26.3582 42.6826C24.8531 42.6826 23.6291 43.9066 23.6291 45.4118C23.6291 46.9169 24.8531 48.1409 26.3582 48.1409C27.8634 48.1409 29.0874 46.9169 29.0874 45.4118C29.0874 43.9078 27.8634 42.6826 26.3582 42.6826Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.0496 49.4586C46.8188 49.4586 45.0039 47.6438 45.0039 45.4129C45.0039 43.182 46.8188 41.3672 49.0496 41.3672C51.2805 41.3672 53.0953 43.182 53.0953 45.4129C53.0953 47.6438 51.2805 49.4586 49.0496 49.4586ZM49.0496 42.6826C47.5445 42.6826 46.3205 43.9066 46.3205 45.4118C46.3205 46.9169 47.5445 48.1409 49.0496 48.1409C50.5548 48.1409 51.7788 46.9169 51.7788 45.4118C51.7788 43.9078 50.5548 42.6826 49.0496 42.6826Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.5601 31.7463H24.5098C24.1464 31.7463 23.8516 31.4514 23.8516 31.088C23.8516 30.7245 24.1464 30.4297 24.5098 30.4297H39.5601C39.9236 30.4297 40.2184 30.7245 40.2184 31.088C40.2184 31.4514 39.9236 31.7463 39.5601 31.7463Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.0294 31.7032C31.666 31.7032 31.3711 31.4118 31.3711 31.0506V23.4963C31.3711 23.1363 31.666 22.8438 32.0294 22.8438C32.3928 22.8438 32.6877 23.1352 32.6877 23.4963V31.0506C32.6877 31.4106 32.3928 31.7032 32.0294 31.7032Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.3677 42.6315C26.0043 42.6315 25.7095 42.3366 25.7095 41.9732V39.2258H21.002C20.6386 39.2258 20.3438 38.9309 20.3438 38.5675V34.5606C20.3438 34.1972 20.6386 33.9023 21.002 33.9023C21.3655 33.9023 21.6603 34.1972 21.6603 34.5606V37.9092H26.3677C26.7312 37.9092 27.026 38.2041 27.026 38.5675V41.9721C27.026 42.3366 26.7312 42.6315 26.3677 42.6315Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0098 42.6107C14.6464 42.6107 14.3516 42.3159 14.3516 41.9524V38.5684C14.3516 38.205 14.6464 37.9102 15.0098 37.9102H20.9996C21.363 37.9102 21.6578 38.205 21.6578 38.5684C21.6578 38.9319 21.363 39.2267 20.9996 39.2267H15.6681V41.9524C15.6681 42.3159 15.3733 42.6107 15.0098 42.6107Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.6934 42.6027C37.33 42.6027 37.0352 42.3079 37.0352 41.9444V38.4599C37.0352 38.0964 37.33 37.8016 37.6934 37.8016H42.3746V34.5216C42.3746 34.1581 42.6694 33.8633 43.0329 33.8633C43.3963 33.8633 43.6912 34.1581 43.6912 34.5216V38.4599C43.6912 38.8233 43.3963 39.1181 43.0329 39.1181H38.3517V41.9444C38.3517 42.3079 38.0569 42.6027 37.6934 42.6027Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.0493 42.6271C48.6859 42.6271 48.391 42.3322 48.391 41.9688V39.1174H43.0333C42.6699 39.1174 42.375 38.8225 42.375 38.4591C42.375 38.0956 42.6699 37.8008 43.0333 37.8008H49.0493C49.4127 37.8008 49.7076 38.0956 49.7076 38.4591V41.9688C49.7076 42.3322 49.4127 42.6271 49.0493 42.6271Z"
            fill="black"
          />
        </svg>
        <div className="flex w-4/5 flex-col items-center px-4">
          <Heading size={HeadingSize.H4} className="mt-4">
            {t('manage-wizard.classes.list.empty.heading')}
          </Heading>
          <p className="mb-8">{t('manage-wizard.classes.list.empty.description')}</p>
          <div className="flex gap-4">
            <Button type={ButtonType.SECONDARY} onClick={() => nextStep && nextStep('importClass')} size={ButtonSize.S}>
              {t('manage-wizard.buttons.import')}
            </Button>
            <Button type={ButtonType.SECONDARY} onClick={() => nextStep && nextStep('createClass')} size={ButtonSize.S}>
              {t('manage-wizard.buttons.add')}
            </Button>
          </div>
        </div>
      </div>
    </StandardModal>
  );
};

export default StartCreateClassStep;
