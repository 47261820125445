import StandardModal from '../../shared/modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import { useManageDocumentClassesWizard } from '../../../contexts/ManageDocumentClassesContext';
import { useCallback, useMemo, useRef, useState } from 'react';
import ManageClass from '../ManageClass';
import { TabStrip } from '../../shared/tab-strip/TabStrip';
import { ModuleDefaultConfiguration } from '../../../models/ClientModuleDefaults';
import { Access } from '../../../models/Access';
import ManageAccess from '../ManageAccess';
import { ModuleTemplate } from '../../../models/Module';
import ClientTemplateModuleService from '../../../services/ClientTemplateModuleService';
import { currentClientAtom } from '../../../recoil/atoms/Clients';
import { useRecoilValue } from 'recoil';
import LanguageUtils from '../../../utils/LanguageUtils';
import ManagePeriodicReview from '../ManagePeriodicReview';
import { PeriodicReviewConfig } from '../../../models/Form';
import { nextTick } from '../../../utils/ReactUtils';
import TemplateModuleService from '../../../services/TemplateModuleService';
import PdfPreferences from '../../form/DownloadPdfPreferences';
import { DefaultDownloadPdfPreferences } from '../../../models/DownloadPdfPreferences';
import { PeriodicReviewUtils } from '../../../utils/PeriodicReviewUtils';
import useInviteUser from '../../../hooks/useInviteUser';
import { ClientFormUserRole } from '../../../models/ClientFormUserRoles';
import PlusIcon from '../../shared/icon/PlusIcon';
import ManageClassConfig from '../ManageClassConfig';

const ManageClassStep = () => {
  const { selectedClass, setSelectedClass, prevStep, clientUsers, onUpdated, nextStep, cancel, classes, onUserInvited } =
    useManageDocumentClassesWizard();
  const { t } = useTranslation(['documents', 'organisation', 'common', 'periodic-review']);
  const [selectedClassInitial] = useState(selectedClass);
  const currentClient = useRecoilValue(currentClientAtom);
  const [isSaving, setIsSaving] = useState(false);
  const reviewDivRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState('');
  const { inviteUserMode, setInviteUserMode, inviteUser, setInvitedUser, invitedUser, setInviteUserDisable, inviteUserDisable } = useInviteUser();

  const internalOnClose = useCallback(() => {
    setInviteUserMode(false);
  }, [setInviteUserMode]);

  const onSaveUserInvite = useCallback(() => {
    setIsSaving(true);
    inviteUser()?.then((res) => {
      setSelectedClass(
        (prev) =>
          prev && {
            ...prev,
            defaults: {
              ...(prev.defaults || {}),
              configuration: {
                ...((prev.defaults || {}).configuration || { accessType: Access.restricted, users: [] }),
                users: [
                  ...((prev.defaults || {}).configuration || { accessType: Access.restricted, users: [] }).users,
                  {
                    userId: res.data.userId,
                    role: invitedUser?.defaultClientFormRole || ClientFormUserRole.Viewer,
                  },
                ],
              },
            },
          },
      );
      onUserInvited();
      setInviteUserMode(false);
      setIsSaving(false);
    });
  }, [inviteUser, invitedUser?.defaultClientFormRole, onUserInvited, setInviteUserMode, setSelectedClass]);

  const onTemplateChange = useCallback(
    (templateModule: ModuleTemplate) => {
      setSelectedClass(
        (prev) =>
          prev && {
            ...prev,
            templateModule: {
              ...templateModule,
            },
          },
      );
    },
    [setSelectedClass],
  );

  const onDefaultsChange = useCallback(
    (newConfig: ModuleDefaultConfiguration) => {
      setSelectedClass(
        (prev) =>
          prev && {
            ...prev,
            defaults: {
              ...(prev.defaults || {}),
              configuration: { ...newConfig },
            },
          },
      );
    },
    [setSelectedClass],
  );

  const onPeriodicReviewChange = useCallback(
    (newConfig: PeriodicReviewConfig | null) => {
      setSelectedClass(
        (prev) =>
          prev && {
            ...prev,
            templateModule: currentClient ? prev.templateModule : { ...prev.templateModule, periodicReviewConfig: newConfig },
            defaults: currentClient
              ? {
                  ...(prev.defaults || {}),
                  configuration: {
                    ...(prev.defaults?.configuration || { accessType: Access.restricted, users: [] }),
                    periodicReviewConfig: newConfig,
                  },
                }
              : prev.defaults,
          },
      );
    },
    [currentClient, setSelectedClass],
  );

  const isImported = useMemo(() => {
    return currentClient && !selectedClass?.templateModule.clientId;
  }, [currentClient, selectedClass?.templateModule.clientId]);

  const save = useCallback(() => {
    if (currentClient && selectedClass?.templateModule.id) {
      setIsSaving(true);
      const service = new ClientTemplateModuleService(currentClient.id);
      const createDefaults = () => {
        if (selectedClass.defaults?.configuration) {
          service
            .createModuleDefaults(selectedClass?.templateModule.id, selectedClass.defaults?.configuration)
            .then(() => {
              onUpdated(selectedClass);
              classes.length > 0 ? nextStep && nextStep('manageClasses') : cancel && cancel();
            })
            .finally(() => setIsSaving(false));
        } else {
          setIsSaving(false);
        }
      };
      if (isImported) {
        createDefaults();
      } else {
        service.updateTemplate(selectedClass?.templateModule.id, selectedClass?.templateModule).then(() => {
          createDefaults();
        });
      }
    } else if (!currentClient && selectedClass?.templateModule.id) {
      // Global document class
      TemplateModuleService.updateTemplate(selectedClass?.templateModule.id, selectedClass?.templateModule)
        .then(() => {
          onUpdated(selectedClass);
          cancel && cancel();
        })
        .finally(() => setIsSaving(false));
    }
  }, [cancel, classes.length, currentClient, isImported, nextStep, onUpdated, selectedClass]);

  const onPreferenceChange = useCallback(
    (key: string, value: boolean) => {
      setSelectedClass((prev) => {
        if (!prev) {
          return prev;
        }

        const updateTemplateModule = (templateModule: ModuleTemplate): ModuleTemplate => {
          const basePreferences = templateModule.downloadPdfPreferences || DefaultDownloadPdfPreferences;
          return {
            ...templateModule,
            downloadPdfPreferences: {
              ...basePreferences,
              [key]: value,
            },
          };
        };

        const updateDefaultsConfiguration = (defaultsConfig?: ModuleDefaultConfiguration): ModuleDefaultConfiguration => {
          const basePreferences =
            defaultsConfig?.downloadPdfPreferences || prev.templateModule?.downloadPdfPreferences || DefaultDownloadPdfPreferences;

          return {
            ...(defaultsConfig || { accessType: Access.restricted, users: [] }),
            downloadPdfPreferences: {
              ...basePreferences,
              [key]: value,
            },
          };
        };

        return {
          ...prev,
          templateModule: currentClient ? prev.templateModule : updateTemplateModule(prev.templateModule || {}),
          defaults: currentClient
            ? {
                ...(prev.defaults || {}),
                configuration: updateDefaultsConfiguration(prev.defaults?.configuration),
              }
            : prev.defaults,
        };
      });
    },
    [currentClient, setSelectedClass],
  );

  const periodicReviewValidationErrors = useMemo(
    () =>
      PeriodicReviewUtils.validate(
        selectedClass?.defaults?.configuration.periodicReviewConfig || selectedClass?.templateModule.periodicReviewConfig,
        t,
      ),
    [selectedClass?.defaults?.configuration.periodicReviewConfig, selectedClass?.templateModule.periodicReviewConfig, t],
  );

  const isPeriodicReviewRequired = useMemo(
    () => !!currentClient && !selectedClass?.templateModule.clientId && !!selectedClass?.templateModule.periodicReviewConfig,
    [currentClient, selectedClass?.templateModule.clientId, selectedClass?.templateModule.periodicReviewConfig],
  );

  const onRemovePeriodicReviewDefaults = useCallback(() => {
    onPeriodicReviewChange(null);
  }, [onPeriodicReviewChange]);

  const canResetPeriodicReview = useMemo(() => {
    return activeTab === 'periodic-review' && isPeriodicReviewRequired && !!selectedClass?.defaults?.configuration.periodicReviewConfig;
  }, [activeTab, isPeriodicReviewRequired, selectedClass?.defaults?.configuration.periodicReviewConfig]);

  return (
    <StandardModal
      title={t('manage-wizard.class.title', { class: LanguageUtils.getTranslation('name', selectedClass?.templateModule.translations ?? {}) })}
      onCancelClick={!inviteUserMode ? () => (currentClient ? prevStep && prevStep() : cancel && cancel()) : internalOnClose}
      cancelButtonTitle={currentClient ? t('manage-wizard.buttons.back') : t('manage-wizard.buttons.cancel')}
      confirmButtonTitle={!inviteUserMode ? t('manage-wizard.buttons.save') : t('common:permissions-modal.manage-add')}
      onConfirmClick={() => (!inviteUserMode ? save() : onSaveUserInvite())}
      confirmDisabled={!inviteUserMode ? selectedClass === selectedClassInitial || periodicReviewValidationErrors.length > 0 : inviteUserDisable}
      confirmDisabledTooltip={periodicReviewValidationErrors.length > 0 ? periodicReviewValidationErrors[0] : undefined}
      confirmLoading={isSaving}
      tertiaryButtonIcon={canResetPeriodicReview ? null : <PlusIcon className="h-3 w-3" />}
      onTertiaryButtonClick={
        canResetPeriodicReview
          ? onRemovePeriodicReviewDefaults
          : activeTab === 'access'
            ? !inviteUserMode
              ? () => setInviteUserMode(true)
              : undefined
            : undefined
      }
      tertiaryButtonTitle={canResetPeriodicReview ? t('periodic-review:reset') : t('common:add-or-invite-modal.switch.invite')}
    >
      <div className="h-[60vh] flex-grow overflow-auto">
        <TabStrip borderless contentClassName="px-4" defaultTabId={`${isImported ? 'access' : 'details'}`} onChange={setActiveTab}>
          <TabStrip.TabHeader id="details" text={t('manage-wizard.tabs.details')} value={null} data-cy="details-tab" />
          <TabStrip.TabHeader id="config" text={t('manage-wizard.tabs.configuration')} value={null} data-cy="config-tab" />
          {currentClient && <TabStrip.TabHeader id="access" text={t('manage-wizard.tabs.access')} value={null} data-cy="access-tab" />}
          <TabStrip.TabHeader id="periodic-review" text={t('manage-wizard.tabs.periodic-review')} value={null} data-cy="periodic-review-tab" />
          <TabStrip.TabHeader id="download-preferences" text={t('manage-wizard.tabs.download')} value={null} data-cy="download-preferences-tab" />
          <TabStrip.TabContent forId="details" data-cy="details-tab-content">
            <div className="mt-8">
              {selectedClass && !isImported && <ManageClass template={selectedClass.templateModule} onTemplateChange={onTemplateChange} />}
              {isImported && (
                <>
                  <div className="py-2 font-medium">{t('organisation:modules.modals.create-class.name')}</div>
                  <div className="pb-4">{LanguageUtils.getTranslation('name', selectedClass?.templateModule.translations || {})}</div>
                  <div className="py-2 font-medium">{t('organisation:modules.modals.create-class.description')}</div>
                  <div className="pb-4">{LanguageUtils.getTranslation('description', selectedClass?.templateModule.translations || {})}</div>
                  <div className="py-2 font-medium">{t('organisation:modules.modals.create-class.prefix')}</div>
                  <div className="pb-4">{LanguageUtils.getTranslation('prefix', selectedClass?.templateModule.translations || {})}</div>
                </>
              )}
            </div>
          </TabStrip.TabContent>
          <TabStrip.TabContent forId="config" data-cy="config-tab-content">
            <div className="mt-4">
              {selectedClass && !isImported && <ManageClassConfig template={selectedClass.templateModule} onTemplateChange={onTemplateChange} />}
              {isImported && (
                <>
                  <div className="py-2 font-medium">{t('organisation:modules.modals.create-class.requires-validation')}</div>
                  <div className="pb-4">{t(`documents:${selectedClass?.templateModule.requiresValidation ? 'true' : 'false'}`)}</div>
                  <div className="py-2 font-medium">{t('organisation:modules.modals.create-class.requires-validation-signature')}</div>
                  <div className="pb-4">{t(`documents:${selectedClass?.templateModule.requiresValidationSignature ? 'true' : 'false'}`)}</div>
                  <div className="py-2 font-medium">{t('organisation:modules.modals.create-class.requires-approval')}</div>
                  <div className="pb-4">{t(`documents:${selectedClass?.templateModule.requiresApproval ? 'true' : 'false'}`)}</div>
                  <div className="py-2 font-medium">{t('organisation:modules.modals.create-class.requires-approval-signature')}</div>
                  <div className="pb-4">{t(`documents:${selectedClass?.templateModule.requiresApprovalSignature ? 'true' : 'false'}`)}</div>
                  <div className="py-2 font-medium">{t('organisation:modules.modals.create-class.requires-owner-acknowledgement')}</div>
                  <div className="pb-4">{t(`documents:${selectedClass?.templateModule.requiresOwnerAcknowledgement ? 'true' : 'false'}`)}</div>
                </>
              )}
            </div>
          </TabStrip.TabContent>
          {currentClient && (
            <TabStrip.TabContent forId="access" data-cy="access-tab-content">
              <div className="mt-4">
                {selectedClass && (
                  <ManageAccess
                    onDefaultsChange={onDefaultsChange}
                    clientUsers={clientUsers}
                    defaults={selectedClass?.defaults?.configuration || { accessType: Access.restricted, users: [] }}
                    requireApprover={selectedClass?.templateModule.requiresApproval}
                    requireValidator={selectedClass?.templateModule.requiresValidation}
                    inviteUserMode={inviteUserMode}
                    onUserInviteValid={(value) => setInviteUserDisable(!value)}
                    onUserInviteChange={setInvitedUser}
                    modalIsValid // Always valid because we don't require all users to be set
                  />
                )}
              </div>
            </TabStrip.TabContent>
          )}
          <TabStrip.TabContent forId="periodic-review" data-cy="periodic-review-tab-content">
            <div ref={reviewDivRef} className="-mr-4 mt-4 h-[53vh] overflow-y-auto pr-4">
              <div className="text-dpm-16 font-medium">{t('manage-wizard.periodic-review')}</div>
              {selectedClass && (
                <ManagePeriodicReview
                  config={selectedClass?.defaults?.configuration.periodicReviewConfig || selectedClass?.templateModule.periodicReviewConfig}
                  onConfigChange={onPeriodicReviewChange}
                  isRequired={isPeriodicReviewRequired}
                  onCheckListItemAdded={() => {
                    nextTick(() => {
                      if (reviewDivRef.current) {
                        // Scroll to the bottom by setting scrollTop to the scrollHeight
                        reviewDivRef.current.scrollTop = reviewDivRef.current.scrollHeight;
                      }
                    });
                  }}
                />
              )}
            </div>
          </TabStrip.TabContent>
          <TabStrip.TabContent forId="download-preferences" data-cy="download-preferences-tab-content">
            <div ref={reviewDivRef} className="-mr-4 mt-4 h-[53vh] overflow-y-auto pr-4">
              <div className="text-dpm-16 font-medium">{t('manage-wizard.download')}</div>
              {selectedClass && (
                <PdfPreferences
                  preferences={selectedClass.defaults?.configuration.downloadPdfPreferences || selectedClass?.templateModule.downloadPdfPreferences}
                  onPreferenceChange={onPreferenceChange}
                />
              )}
            </div>
          </TabStrip.TabContent>
        </TabStrip>
      </div>
    </StandardModal>
  );
};

export default ManageClassStep;
