import StandardModal from '../../shared/modal/variants/StandardModal';
import WizardStepsIndicator from '../../shared/wizard/WizardStepsIndicator';
import { useTranslation } from 'react-i18next';
import { useManageDocumentClassesWizard } from '../../../contexts/ManageDocumentClassesContext';
import { useCallback, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../../recoil/atoms/Clients';
import ManagePeriodicReview from '../ManagePeriodicReview';
import { nextTick } from '../../../utils/ReactUtils';
import { PeriodicReviewConfig } from '../../../models/Form';
import { PeriodicReviewUtils } from '../../../utils/PeriodicReviewUtils';
import LanguageUtils from '../../../utils/LanguageUtils';

const CreatePeriodicReviewStep = () => {
  const { nextStep, prevStep, stepNames, newTemplate, setTemplate, defaults, setDefaults } = useManageDocumentClassesWizard();
  const { t } = useTranslation(['documents', 'organisation', 'periodic-review']);
  const reviewDivRef = useRef<HTMLDivElement>(null);

  const currentClient = useRecoilValue(currentClientAtom);

  const onPeriodicReviewConfigChange = useCallback(
    (config: PeriodicReviewConfig | null) => {
      if (currentClient) {
        setDefaults && setDefaults((prev) => ({ ...prev, periodicReviewConfig: config }));
      } else {
        setTemplate && setTemplate((prev) => ({ ...prev, periodicReviewConfig: config }));
      }
    },
    [currentClient, setDefaults, setTemplate],
  );

  const periodicReviewValidationErrors = useMemo(
    () => PeriodicReviewUtils.validate(defaults.periodicReviewConfig || newTemplate.periodicReviewConfig, t),
    [defaults.periodicReviewConfig, newTemplate.periodicReviewConfig, t],
  );

  return (
    <>
      <StandardModal
        title={t('manage-wizard.create-class.title')}
        cancelButtonTitle={t('manage-wizard.buttons.back')}
        onCancelClick={prevStep}
        confirmButtonTitle={t('manage-wizard.buttons.next')}
        confirmDisabled={!LanguageUtils.getTranslation('name', newTemplate.translations).trim().length || periodicReviewValidationErrors.length > 0}
        confirmDisabledTooltip={periodicReviewValidationErrors.length > 0 ? periodicReviewValidationErrors[0] : undefined}
        onConfirmClick={() => nextStep && nextStep('createDownloadPdfPreferences')}
        tertiaryButtonIcon={null}
      >
        {stepNames && nextStep && (
          <WizardStepsIndicator
            activeStepIndex={3}
            stepNames={stepNames.filter(
              (x) =>
                x === 'createClass' ||
                x === 'createClassConfig' ||
                (currentClient && x === 'createAccess') ||
                x === 'createPeriodicReview' ||
                x === 'createDownloadPdfPreferences',
            )}
            onStepChange={nextStep}
          />
        )}
        <div ref={reviewDivRef} className="h-[60vh] flex-grow overflow-auto px-1 pr-2">
          <div className="text-dpm-16 font-medium">{t('manage-wizard.periodic-review')}</div>
          <ManagePeriodicReview
            config={defaults.periodicReviewConfig || newTemplate.periodicReviewConfig}
            isRequired={!!currentClient && !!newTemplate.periodicReviewConfig}
            onConfigChange={onPeriodicReviewConfigChange}
            onCheckListItemAdded={() => {
              nextTick(() => {
                if (reviewDivRef.current) {
                  // Scroll to the bottom by setting scrollTop to the scrollHeight
                  reviewDivRef.current.scrollTop = reviewDivRef.current.scrollHeight;
                }
              });
            }}
          />
        </div>
      </StandardModal>
    </>
  );
};

export default CreatePeriodicReviewStep;
