import StandardModal from '../../shared/modal/variants/StandardModal';
import WizardStepsIndicator from '../../shared/wizard/WizardStepsIndicator';
import { useTranslation } from 'react-i18next';
import { useManageDocumentClassesWizard } from '../../../contexts/ManageDocumentClassesContext';
import { useState, useCallback, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../../recoil/atoms/Clients';
import ClientTemplateModuleService from '../../../services/ClientTemplateModuleService';
import TemplateModuleService from '../../../services/TemplateModuleService';
import PdfPreferences from '../../form/DownloadPdfPreferences';
import { DefaultDownloadPdfPreferences } from '../../../models/DownloadPdfPreferences';
import LanguageUtils from '../../../utils/LanguageUtils';

const CreateDownloadPreferencesStep = () => {
  const { nextStep, prevStep, stepNames, newTemplate, setTemplate, defaults, setDefaults, reset, onCreated } = useManageDocumentClassesWizard();
  const { t } = useTranslation(['documents', 'organisation', 'form']);
  const reviewDivRef = useRef<HTMLDivElement>(null);

  const currentClient = useRecoilValue(currentClientAtom);
  const [isSaving, setIsSaving] = useState(false);

  const createClass = useCallback(() => {
    setIsSaving(true);
    if (currentClient) {
      const service = new ClientTemplateModuleService(currentClient?.id);
      service.createTemplateAndInstantiate(newTemplate).then((res) => {
        service
          .createModuleDefaults(res.data.id, defaults)
          .then(() => {
            reset();
            onCreated(res.data);
            nextStep && nextStep('manageClasses');
          })
          .finally(() => setIsSaving(false));
      });
    } else {
      TemplateModuleService.createTemplate(newTemplate).then((res) => {
        onCreated(res.data);
      });
    }
  }, [currentClient, defaults, newTemplate, nextStep, onCreated, reset]);

  const onPreferenceChange = useCallback(
    (key: string, value: boolean) => {
      if (currentClient) {
        setDefaults &&
          setDefaults((prev) => ({
            ...prev,
            downloadPdfPreferences: { ...(prev.downloadPdfPreferences || DefaultDownloadPdfPreferences), [key]: value },
          }));
      } else {
        setTemplate &&
          setTemplate((prev) => ({
            ...prev,
            downloadPdfPreferences: { ...(prev.downloadPdfPreferences || DefaultDownloadPdfPreferences), [key]: value },
          }));
      }
    },
    [currentClient, setDefaults, setTemplate],
  );

  return (
    <>
      <StandardModal
        title={t('manage-wizard.create-class.title')}
        cancelButtonTitle={t('manage-wizard.buttons.back')}
        onCancelClick={prevStep}
        confirmButtonTitle={t('manage-wizard.buttons.create')}
        confirmDisabled={!LanguageUtils.getTranslation('name', newTemplate.translations).trim().length}
        onConfirmClick={createClass}
        confirmLoading={isSaving}
        tertiaryButtonIcon={null}
      >
        {stepNames && nextStep && (
          <WizardStepsIndicator
            activeStepIndex={4}
            stepNames={stepNames.filter(
              (x) =>
                x === 'createClass' ||
                x === 'createClassConfig' ||
                (currentClient && x === 'createAccess') ||
                x === 'createPeriodicReview' ||
                x === 'createDownloadPdfPreferences',
            )}
            onStepChange={nextStep}
          />
        )}
        <div ref={reviewDivRef} className="h-[60vh] flex-grow overflow-auto px-1 pr-2">
          <div className="text-dpm-16 font-medium">{t('manage-wizard.download')}</div>
          <PdfPreferences
            preferences={defaults.downloadPdfPreferences || newTemplate.downloadPdfPreferences}
            onPreferenceChange={onPreferenceChange}
          />
        </div>
      </StandardModal>
    </>
  );
};

export default CreateDownloadPreferencesStep;
