import { useTranslation } from 'react-i18next';
import StandardModal from '../../shared/modal/variants/StandardModal';
import { Heading, HeadingSize } from '../../shared/text/Heading';
import Button, { ButtonType } from '../../shared/form-control/Button';
import { useManageDocumentClassesWizard } from '../../../contexts/ManageDocumentClassesContext';
import welcomeSvg from '../../../assets/images/doc-space-start.svg';
import welcomeNoAccessSvg from '../../../assets/images/doc-space-start-no-access.svg';
import usePermissions from '../../../hooks/permissions/usePermissions';
import { Roles } from '../../../models/Role';

const WelcomeStep = () => {
  const { nextStep } = useManageDocumentClassesWizard();
  const { t } = useTranslation('documents');
  const hasPermission = usePermissions();

  return (
    <StandardModal tertiaryButtonIcon={null} uncloseable>
      <div className="flex flex-grow flex-col items-center px-1 text-center">
        <img
          src={hasPermission(Roles.TeamMember) ? welcomeSvg : welcomeNoAccessSvg}
          className="object-cover"
          alt={t('manage-wizard.welcome.title')}
        />
        <div className="px-10">
          <Heading size={HeadingSize.H3} className="my-4">
            {hasPermission(Roles.TeamMember) ? t('manage-wizard.welcome.title') : t('manage-wizard.welcome.title-no-access')}
          </Heading>
          <p>{hasPermission(Roles.TeamMember) ? t('manage-wizard.welcome.description') : t('manage-wizard.welcome.description-no-access')}</p>
          {hasPermission(Roles.TeamMember) && (
            <Button type={ButtonType.PRIMARY} className="mt-8" onClick={() => nextStep && nextStep('start')}>
              {t('manage-wizard.buttons.start')}
            </Button>
          )}
        </div>
      </div>
    </StandardModal>
  );
};

export default WelcomeStep;
