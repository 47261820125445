import StandardModal from '../../shared/modal/variants/StandardModal';
import WizardStepsIndicator from '../../shared/wizard/WizardStepsIndicator';
import { useTranslation } from 'react-i18next';
import ManageClass from '../ManageClass';
import { useManageDocumentClassesWizard } from '../../../contexts/ManageDocumentClassesContext';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../../recoil/atoms/Clients';
import LanguageUtils from '../../../utils/LanguageUtils';

const CreateClassStep = () => {
  const { nextStep, prevStep, stepNames, setTemplate, newTemplate } = useManageDocumentClassesWizard();
  const { t } = useTranslation(['documents', 'organisation']);
  const currentClient = useRecoilValue(currentClientAtom);

  return (
    <StandardModal
      title={t('manage-wizard.create-class.title')}
      cancelButtonTitle={t('manage-wizard.buttons.back')}
      onCancelClick={prevStep}
      confirmButtonTitle={t('manage-wizard.buttons.next')}
      confirmDisabled={!LanguageUtils.getTranslation('name', newTemplate.translations).trim().length}
      onConfirmClick={() => nextStep && nextStep('createClassConfig')}
      tertiaryButtonIcon={null}
    >
      {stepNames && nextStep && (
        <WizardStepsIndicator
          activeStepIndex={0}
          stepNames={stepNames.filter(
            (x) =>
              x === 'createClass' ||
              x === 'createClassConfig' ||
              (currentClient && x === 'createAccess') ||
              x === 'createPeriodicReview' ||
              x === 'createDownloadPdfPreferences',
          )}
          onStepChange={nextStep}
        />
      )}
      <div className="h-[60vh] flex-grow overflow-auto px-1">
        <div className="text-dpm-16 font-medium">{t('manage-wizard.details')}</div>
        <ManageClass template={newTemplate} onTemplateChange={(template) => setTemplate && setTemplate(template)} />
      </div>
    </StandardModal>
  );
};

export default CreateClassStep;
