import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PeriodicReviewContext } from '../../contexts/PeriodicReviewContext';
import { PeriodicReviewConfig } from '../../models/Form';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import ReviewCheckList from '../shared/periodic-review/ReviewCheckList';
import ReviewConfig from '../shared/periodic-review/ReviewConfig';
import ReviewGoal from '../shared/periodic-review/ReviewGoal';
import { EmptyPeriodicReviewConfig } from '../shared/periodic-review/ReviewWizard';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import { v4 as uuid } from 'uuid';

type Props = {
  config: PeriodicReviewConfig | null | undefined;
  onConfigChange: (config: PeriodicReviewConfig | null) => void;
  onCheckListItemAdded: () => void;
  isRequired?: boolean;
};
const ManagePeriodicReview: FC<Props> = (props) => {
  const { config, onConfigChange, onCheckListItemAdded, isRequired } = props;
  const { t } = useTranslation(['documents', 'organisation', 'periodic-review']);
  const [showPeriodicReview, setShowPeriodicReview] = useState(!!config);

  const [showPeriodicReviewCheckList, setShowPeriodicReviewCheckList] = useState(config?.checkList && config?.checkList?.length > 0);

  const [showRemoveReviewModal, setShowRemoveReviewModal] = useState(false);

  const [showRemoveReviewCheckListModal, setShowRemoveReviewCheckListModal] = useState(false);

  const togglePeriodicReview = useCallback(
    (value: boolean) => {
      if (value) {
        onConfigChange(EmptyPeriodicReviewConfig);
        setShowPeriodicReview(value);
      } else {
        setShowRemoveReviewModal(true);
      }
    },
    [onConfigChange],
  );

  const removePeriodicReview = useCallback(() => {
    setShowPeriodicReview(false);
    setShowRemoveReviewModal(false);
    setShowPeriodicReviewCheckList(false);
    onConfigChange(null);
  }, [onConfigChange]);

  const removePeriodicReviewCheckList = useCallback(() => {
    setShowPeriodicReviewCheckList(false);
    setShowRemoveReviewCheckListModal(false);
    onConfigChange({ ...(config || EmptyPeriodicReviewConfig), checkList: [] });
  }, [config, onConfigChange]);

  const onPeriodicReviewChange = useCallback(
    (value: PeriodicReviewConfig) => {
      if (!value.checkList?.length) {
        setShowPeriodicReviewCheckList(false);
      }
      onConfigChange(value);
    },
    [onConfigChange],
  );

  const togglePeriodicReviewChecklist = useCallback(
    (value: boolean) => {
      if (value) {
        setShowPeriodicReviewCheckList(value);
        onConfigChange({
          ...(config || EmptyPeriodicReviewConfig),
          checkList: [...(config?.checkList || []), { id: uuid(), translations: {} }],
        });
      } else if (config?.checkList?.length) {
        setShowRemoveReviewCheckListModal(true);
      }
    },
    [config, onConfigChange],
  );
  return (
    <>
      <div className="text-dpm-12">{t('periodic-review:description')}</div>
      <div className="mt-2 flex items-center justify-between">
        <div>{t('periodic-review:toggle-checkbox')}</div>
        <div>
          <Checkbox
            value={showPeriodicReview}
            onChange={(value) => togglePeriodicReview(value)}
            slider
            sliderSize={SliderSize.S}
            disabled={isRequired}
          />
        </div>
      </div>
      {showPeriodicReview && (
        <>
          <PeriodicReviewContext.Provider value={{ config: config || EmptyPeriodicReviewConfig }}>
            <div className="flex flex-col gap-4 py-4">
              <ReviewGoal onChange={onPeriodicReviewChange} />
              <ReviewConfig onChange={onPeriodicReviewChange} />
              <div>
                <div className="pb-2">
                  <div>{t('periodic-review:checklist.title')}</div>
                  <div className="text-dpm-12">{t('periodic-review:checklist.description')}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>{t('periodic-review:checklist.toggle')}</div>
                  <div>
                    <Checkbox value={showPeriodicReviewCheckList} onChange={(value) => togglePeriodicReviewChecklist(value)} />
                  </div>
                </div>
                {showPeriodicReviewCheckList && (
                  <ReviewCheckList
                    onChange={(value) => {
                      onPeriodicReviewChange(value);
                      onCheckListItemAdded();
                    }}
                  />
                )}
              </div>
            </div>
          </PeriodicReviewContext.Provider>
          <ModalContext.Provider value={{ open: showRemoveReviewModal, modalWidth: 'w-2/5', onClose: () => setShowRemoveReviewModal(false) }}>
            <ConfirmationModal
              onConfirm={removePeriodicReview}
              onCancel={() => setShowRemoveReviewModal(false)}
              title={t('periodic-review:modals.delete.title')}
              description={t('periodic-review:modals.delete.description')}
              confirmText={t('periodic-review:modals.delete.buttons.confirm')}
            />
          </ModalContext.Provider>
          <ModalContext.Provider
            value={{ open: showRemoveReviewCheckListModal, modalWidth: 'w-2/5', onClose: () => setShowRemoveReviewCheckListModal(false) }}
          >
            <ConfirmationModal
              onConfirm={removePeriodicReviewCheckList}
              onCancel={() => setShowRemoveReviewCheckListModal(false)}
              title={t('periodic-review:modals.delete-all-checklist.title')}
              description={t('periodic-review:modals.delete-all-checklist.description')}
              confirmText={t('periodic-review:modals.delete-all-checklist.buttons.confirm')}
            />
          </ModalContext.Provider>
        </>
      )}
    </>
  );
};

export default ManagePeriodicReview;
