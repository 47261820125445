import { t } from 'i18next';
import { FC, useCallback, useEffect } from 'react';
import { ModuleTemplate } from '../../models/Module';
import TranslatableInput from '../shared/form-control/TranslatableInput';

type CreateClassProps = {
  template: ModuleTemplate;
  onTemplateChange: (template: ModuleTemplate) => void;
};

const ManageClass: FC<CreateClassProps> = (props) => {
  const { template, onTemplateChange } = props;

  const addModuleValue = useCallback(
    <TKey extends keyof ModuleTemplate>(key: TKey, value: ModuleTemplate[TKey]) => {
      onTemplateChange({ ...template, [key]: value });
    },
    [template, onTemplateChange],
  );

  useEffect(() => {
    if (!template?.requiresValidation && template?.requiresValidationSignature) {
      addModuleValue('requiresValidationSignature', false);
    }
  }, [addModuleValue, template?.requiresValidation, template?.requiresValidationSignature]);

  useEffect(() => {
    if (!template?.requiresApproval && template?.requiresApprovalSignature) {
      addModuleValue('requiresApprovalSignature', false);
    }
  }, [addModuleValue, template?.requiresApproval, template?.requiresApprovalSignature]);

  return (
    <>
      <TranslatableInput
        data-cy="new-class-name"
        placeholder={t('organisation:modules.modals.create-class.name')}
        label={t('organisation:modules.modals.create-class.name')}
        aria-label={t('organisation:modules.modals.create-class.name')}
        translationKey="name"
        translations={template?.translations || {}}
        onTranslationsChange={(translations) => addModuleValue('translations', translations)}
        maxLength={150}
        helpText={t('organisation:modules.modals.create-class.class-help')}
        autoFocus
      />
      <TranslatableInput
        data-cy="new-class-description"
        className="w-full resize-none"
        placeholder={t('organisation:modules.modals.create-class.description')}
        label={t('organisation:modules.modals.create-class.description')}
        aria-label={t('organisation:modules.modals.create-class.description')}
        translationKey="description"
        translations={template?.translations || {}}
        onTranslationsChange={(translations) => addModuleValue('translations', translations)}
        maxLength={1000}
        inputType={'multiline'}
      />
      <TranslatableInput
        data-cy="new-class-prefix"
        placeholder={t('organisation:modules.modals.create-class.prefix')}
        label={t('organisation:modules.modals.create-class.prefix')}
        aria-label={t('organisation:modules.modals.create-class.prefix')}
        translationKey="prefix"
        translations={template?.translations || {}}
        onTranslationsChange={(translations) => addModuleValue('translations', translations)}
        maxLength={150}
        helpText={t('organisation:modules.modals.create-class.prefix-help')}
      />
    </>
  );
};

export default ManageClass;
